
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class LndMobileMenu extends Vue {
  @Prop({})
  active!: boolean

  public showLngList = true
  public locales = [
    { code: 'en', iso: 'en-US', file: 'en.json', title: 'English' },
    { code: 'ru', iso: 'ru-EU', file: 'ru.json', title: 'Русский' },
  ]

  public async switchLocalePath(locale) {
    this.$root.$i18n.locale = locale
    localStorage.setItem('locale', locale)

    this.showLngList = false
    setTimeout(() => {
      this.showLngList = true
    }, 300)
  }

  // public mounted() {
  //   console.debug('%c lnd/Header.vue mounted', 'color:aqua;font-size:24px;')
  // }
}
