
import { Component, Vue } from 'vue-property-decorator'
import '@/assets/css/landing.min.css'
import '@/assets/css/landing.extra.css'

@Component({})
export default class LndScripts extends Vue {
  private fancyStyleUrl = 'https://cdn.jsdelivr.net/npm/@fancyapps/ui@5.0/dist/fancybox/fancybox.css'
  private fancyScriptUrl = 'https://cdn.jsdelivr.net/npm/@fancyapps/ui@5.0/dist/fancybox/fancybox.umd.js'
  private telInputStyleUrl = 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.1.0/build/css/intlTelInput.css'
  private telInputScriptUrl = 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.1.0/build/js/intlTelInput.min.js'

  public initFancybox() {
    console.debug('%c initFancybox', 'color:orangered;font-size:18px;')

    // Fancybox STYLES
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = this.fancyStyleUrl
    document.head.prepend(link)

    // Fancybox SCRIPT
    const script = document.createElement('script')
    script.src = this.fancyScriptUrl
    document.head.appendChild(script)
  }

  public startFancybox() {
    console.debug('%c startFancybox', 'color:gold;font-size:18px;')
    // @ts-ignore
    if (!!window.Fancybox) {
      // @ts-ignore
      window.Fancybox.bind('[data-fancybox]', {})
    } else {
      setTimeout(this.startFancybox, 1000)
    }
  }

  public initTelInput() {
    // TelInput STYLES
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = this.telInputStyleUrl
    document.head.prepend(link)

    // TelInput SCRIPT
    const script = document.createElement('script')
    script.src = this.telInputScriptUrl
    document.head.appendChild(script)
  }

  public beforeMount() {
    this.initFancybox()
    this.initTelInput()
  }

  public mounted() {
    console.debug('%c LndScripts is mounted!', 'color:lime;font-size:18px;')
    this.startFancybox()
  }
}
