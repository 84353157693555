
import { Component, Vue } from 'vue-property-decorator'
import Spinner from '@/components/Spinner.vue'
import { dispatchLogIn } from '@/store/main/actions'

@Component({
  components: { Spinner },
})
export default class LndPopupLogin extends Vue {
  public loading: boolean = false
  public email: string = ''
  public password: string = ''
  public code: any = null
  public formError: boolean = false
  public loginError: boolean = false

  public validateData() {
    if (!this.email || !this.password) {
      this.formError = true
    } else {
      this.formError = false
    }
    return !this.formError
  }

  public async handleSubmit() {
    this.loading = true
    this.loginError = false
    if (!await this.validateData()) {
      return null
    }
    const result = await dispatchLogIn(this.$store, { username: this.email, password: this.password, code: this.code })
    if (result) {
      // Перед переходом на страницу дашборда
      // неоходимо сбросить историю живочата clearHistory()
      // @ts-ignore
      if (window.jivo_api) {
        // @ts-ignore
        console.debug('%c window.jivo_api:', 'color:aqua;', window.jivo_api)

        // @ts-ignore
        const clearHistoryResult = await window.jivo_api.clearHistory()
        console.debug('%c PopupLogin.vue clearHistoryResult:', 'color:lime;font-size:18px;', clearHistoryResult)
      }

      window.location.pathname = '/dashboard'
    } else {
      this.loginError = true
    }
    this.loading = false
  }
}
