
import { Component, Vue } from 'vue-property-decorator'
import Spinner from '@/components/Spinner.vue'
import { dispatchSubmitResetPassword } from '@/store/main/actions'

@Component({
  components: { Spinner },
})
export default class LndPopupResetPassword extends Vue {
  public loading: boolean = false

  public resetEmail: string = ''
  public formError: boolean = false
  public resetError: boolean = false
  public resetOK: boolean = false

  public validateForm() {
    if (!this.resetEmail) {
      this.formError = true
    } else {
      this.formError = false
    }
    return !this.formError
  }

  public async handleSubmit() {
    this.resetError = false
    if (await this.validateForm()) {
      this.loading = true
      const response = await dispatchSubmitResetPassword(this.$store, { email: this.resetEmail })
      if (response) {
        this.resetOK = true
      } else {
        this.resetError = true
        // this.$toast.error(this.$t('Error').toString());
      }
      this.loading = false
    }
  }
}
