<template>
  <div class="header-wrap">
    <header
      class="header"
      :class="{ 'header--mobile-active': isMobileMenuOpen }"
    >
      <div class="container header__container">
        <router-link
          to="/"
          class="header__logo logo-link"
        >
          <img
            src="@/assets/images/landing/logo.svg"
            class="logo-link__img"
            alt="DexNet"
          >
        </router-link>
  
        <nav class="header__nav">
          <ul class="header__nav-list">
            <li class="header__nav-item">
              <router-link
                to="/"
                class="header__nav-link"
              >
                {{ $t('Home') }}
              </router-link>
            </li>
            <li class="header__nav-item">
              <a href="#node" class="header__nav-link">
                DexNode
              </a>
            </li>
            <li class="header__nav-item">
              <a href="#services" class="header__nav-link">
                {{ $t('Services') }}
              </a>
            </li>
            <li class="header__nav-item">
              <router-link
                to="/about"
                class="header__nav-link"
              >
                {{ $t('About') }}
              </router-link>
            </li>
          </ul>
        </nav>
  
        <div class="header__buttons">
          <div class="languages-list header__languages">
            <p class="languages-list__current">
              <!-- {{ $i18n.locale.toUpperCase() }} -->
                {{ currentLocaleTitle }}
            </p>
            <ul
              v-if="showLngList"
              class="languages-list__items languages-list__items_position_bottom"
            >
              <li
                v-for="locale in locales"
                :key="`locale-${locale.code}`"
                class="languages-list__item"
                :class="{ 'languages-list__item_current': locale.code === $root.$i18n.locale }"
                @click.prevent="switchLocalePath(locale.code)"
              >
                {{ locale.title }}
              </li>
            </ul>
          </div>
  
  <!--        <button-->
  <!--          class="header__btn-sign button"-->
  <!--          type="button"-->
  <!--          data-type="popup-sign"-->
  <!--        >-->
  <!--          Sign In (btn)-->
  <!--        </button>-->
          <button
            class="header__btn-sign button"
            @click="$emit('openPopupLogin')"
          >
            {{ $t('Sign In') }}
          </button>
        </div>

        <!-- @click="$emit('openMobileMenu')" -->
        <!-- <button
          class="header__open-mobile"
          type="button"
          @click="isMobileMenuOpen = !isMobileMenuOpen"
        >
          <span class="visually-hidden">Open mobile menu</span>
        </button> -->

        <div class="menu-btn" :class="{ 'active': isMobileMenuOpen }" id="menu-btn" @click="isMobileMenuOpen = !isMobileMenuOpen">
            <div class="menu-btn__burger"></div>
        </div>
      </div>

      <div
        class="header__mobile-content container"
        :class="{ 'header__mobile-content--active': isMobileMenuOpen }"
      >
        <nav class="aside-page__nav">
          <ul
            class="aside-page__nav-list"
            @click="closeMobileMenu"
          >
            <li
              class="aside-page__nav-item"
            >
              <router-link
                to="/"
                class="aside-page__nav-link"
              >
                {{ $t('Home') }}
              </router-link>
            </li>
            <li
              class="aside-page__nav-item"
            >
              <a
                href="#node"
                class="aside-page__nav-link"
              >
                DexNode
              </a>
            </li>
            <li
              class="aside-page__nav-item"
            >
              <a
                href="/#services"
                class="aside-page__nav-link"
              >
                {{ $t('Services') }}
              </a>
            </li>
            <li
              class="aside-page__nav-item"
            >
              <router-link
                to="/about"
                class="aside-page__nav-link"
              >
                {{ $t('About') }}
              </router-link>
            </li>
          </ul>
        </nav>
        <div class="aside-page__footer">
          <div
            ref="lngList"
            class="languages-list aside-page__languages"
          >

            <p class="languages-list__current">
              {{ $i18n.locale.toUpperCase() }}
            </p>

            <ul
              v-if="showLngList"
              class="languages-list__items languages-list__items_position_up"
            >
              <li
                v-for="locale in locales"
                :key="`locale-${locale.code}`"
                class="languages-list__item"
                :class="{ 'languages-list__item_current': locale.code === $root.$i18n.locale }"
                @click.prevent="switchLocalePath(locale.code)"
              >
                {{ locale.title }}
              </li>
            </ul>
          </div>

          <button
            class="aside-page__btn-sign button"
            type="button"
            @click="$emit('openPopupLogin')"
          >
            {{ $t('Sign In') }}
          </button>
        </div>
      </div>
    </header>
  </div>
</template>

<script >
export default  {
  name: 'LndHeader',
  data: () => ({
    showLngList: true,
    locales: [
      { code: 'en', iso: 'en-US', file: 'en.json', title: 'English' },
      { code: 'ru', iso: 'ru-EU', file: 'ru.json', title: 'Русский' },
    ],

    isMobileMenuOpen: false,
  }),
  watch: {
    isMobileMenuOpen(val) {
      if (val) {
        document.documentElement.classList.add('overflow-hidden')
      } else {
        document.documentElement.classList.remove('overflow-hidden')
      }
    },
  },
  computed: {
    currentLocaleTitle() {
      return this.locales.find(
        (locale) => locale.code === this.$root.$i18n.locale
      )?.title
    },
  },
  methods: {
    async switchLocalePath(locale) {
      this.$root.$i18n.locale = locale
      localStorage.setItem('locale', locale)

      this.showLngList = false
      setTimeout(() => {
        this.showLngList = true
      }, 300)
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false
    },
  },
  mounted() {
    setTimeout(() => {
      if (window.location.hash) {
        const hash = window.location.hash.slice(1)
        const el = document.getElementById(hash)
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }, 500)
  }
}
</script>
<style scoped>
.header-wrap {
  width: 100%;
  min-height: 78px;
}

.header {
  background-color: var(--root-color) !important;
  transition: all 0.6s linear !important;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  height: 78px;
}

.header--mobile-active {
  height: 100svh;
  overflow: hidden;
}

.header__mobile-content {
  height: 0px !important;
  overflow: hidden;
  /* transition: all 0.3s cubic-bezier(1,.05,.84,.24) !important; */
  /* transition: all 0.6s cubic-bezier(1,.05,.84,.24) !important; */
  transition: all 0.6s cubic-bezier(.71,-0.04,.91,.43) !important;
  filter: blur(20px);
  margin-top: 20px;
}

.header__mobile-content--active {
  height: 300px !important;
  filter: blur(0);
}

.menu-btn {
  position: relative;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .menu-btn {
    display: none;
  }
}

.menu-btn__burger,
.menu-btn__burger::before,
.menu-btn__burger::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease-in-out;
  border-radius: 2px;
}

.menu-btn__burger {
  top: 50%;
  transform: translateY(-50%);
}

.menu-btn__burger::before {
  top: -10px;
}

.menu-btn__burger::after {
  top: 10px;
}

.menu-btn.active .menu-btn__burger {
  background-color: transparent;
}

.menu-btn.active .menu-btn__burger::before {
  top: 0;
  transform: rotate(45deg);
}

.menu-btn.active .menu-btn__burger::after {
  top: 0;
  transform: rotate(135deg);
}
</style>